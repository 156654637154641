// Copyright © 2022 The Things Industries B.V.

.introduction
  display: flex
  flex-direction: row
  margin-bottom: $ls.xxs

.info
  margin-top: 0

