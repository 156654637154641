// Copyright © 2021 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.branding
  color: $tc-deep-gray
  border-radius: $br.s
  font-weight: $fw.bold

  &-icon
    margin-right: $cs.xxs

  &-cluster
    text-transform: uppercase
    font-family: $font-family-mono
    font-size: $fs.s

  &-text
    font-size: $fs.s
    &:not(:first-child)
      margin-left: $cs.xxs
